// export default function track(type, event, properties) {
//   console.log('type', type);
//   console.log('event', event);
//   console.log('properties', JSON.stringify(properties));
// }
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createProvider } from '../vue-apollo';
import ANALYTICS_TRACK from '../graphql/AnalyticsTrack.gql';

const cache = new InMemoryCache();

const analytics = {
  async track(event, properties) {
    const type = 'track';
    const provider = createProvider({ cache });
    const apollo = provider.defaultClient;

    const propsToSave = [...properties];

    propsToSave.push({ name: 'url', value: window.location.href });
    propsToSave.push({ name: 'title', value: document.title });

    const result = await apollo
      .mutate({
        mutation: ANALYTICS_TRACK,
        variables: {
          type,
          event,
          properties: propsToSave,
        },
      })
      .catch((error) => {
        console.log('error.message', error.message);
        // this.getLessonError = error.message
        //   ? error.message.replace('GraphQL error: ', '')
        //   : 'error.';
      });

    return result;
  },
  async page(event, properties) {
    const type = 'page';
    const provider = createProvider({ cache });
    const apollo = provider.defaultClient;

    const propsToSave = [...properties];

    propsToSave.push({ name: 'url', value: window.location.href });
    propsToSave.push({ name: 'title', value: document.title });

    const result = await apollo
      .mutate({
        mutation: ANALYTICS_TRACK,
        variables: {
          type,
          event,
          properties: propsToSave,
        },
      })
      .catch((error) => {
        console.log('error.message', error.message);
        // this.getLessonError = error.message
        //   ? error.message.replace('GraphQL error: ', '')
        //   : 'error.';
      });

    return result;
  },
};

export default analytics;
