<template>
  <div class="mx-auto content-wrapper" :class="locked ? 'locked' : 'unlocked'" @click="goToLink()">
    <div class="lesson-wrapper text-center">
      <v-img v-if="lessonType === 'gap'" class="lesson-image mx-auto mb-3" height="200px" width="225px" fill
        :src="previewImageURL ? previewImageURL : '/img/video-placeholder.jpg'"></v-img>
      <v-img v-if="lessonType === 'series'" class="lesson-image mx-auto mb-3" height="121px" width="220px" fill
        :src="previewImageURL ? previewImageURL : '/img/video-placeholder.jpg'"></v-img>
    </div>
    <v-container>
      <div class="row">
        <div class="title" :class="locked ? 'locked' : 'unlocked'">
          {{ name }}
        </div>

        <div class="check ml-2" v-if="completed">
          <i class="fas fa-check"></i>
        </div>
      </div>

      <div class="row" v-if="!hideProgressBar">
        <v-progress-linear :rounded="true" :value="progressPercent" color="#C7070E" background-color="#ffffff"
          height="10" class="mt-2"></v-progress-linear>
      </div>

      <div class="row mb-12">
        <div class="font-weight-bold mt-2">
          {{ numLessons }} Lessons | Estimated Time {{ estimatedTime }}
        </div>
      </div>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.lesson-wrapper {
  position: relative;
}

.check {
  color: var(--v-primary-base);
}

.content-wrapper {
  &.unlocked {
    cursor: pointer;
  }

  &.locked {
    .lesson-image {
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }
}

.font-weight-bold {
  color: var(--v-accent-lighten1);
  padding-top: 10px;
}

.title {
  color: var(--v-accent-lighten3);
  font-weight: bold;

  &.unlocked {
    color: var(--v-primary-base);
  }

  &.section-active {
    color: var(--v-primary-base);
  }
}
</style>

<script>
export default {
  data() {
    return {};
  },
  props: {
    name: String,
    link: String,
    numLessons: Number,
    estimatedTime: String, // 1h 59 min
    previewImageURL: String,
    completed: Boolean,
    locked: Boolean,
    progressPercent: Number,
    hideProgressBar: Boolean,
    lessonType: { type: String, default: "gap" },
  },
  mounted() { },
  watch: {
    // showDialog(newVal) {
    //   // watch it
    //   this.videoCensoredDialog = newVal;
    // },
  },
  methods: {
    goToLink() {
      if (this.link) {
        this.$router.push(this.link);
      }
    },
  },
};
</script>
