<template>
  <div class="course-list">
    <v-container>
      <h2>Gap Selling Online Training</h2>
      <v-row justify="center">
        <v-col class="pa-8" cols="12" md="4">
          <VideoCard name="Gap Selling Online Training"
            previewImageURL="https://storage.googleapis.com/asg-assets/lesson-images/1.png" :numLessons="8"
            estimatedTime="6h 14min" :completed="true" :locked="false" :progressPercent="gapProgress"
            link="/course-list?checkpickup=true"></VideoCard>
        </v-col>
      </v-row>
      <hr style="border-top: 1px solid red" />
      <div v-if="auth.authUser.account.selectedPlanId == '1' ||
              auth.authUser.account.selectedPlanId == 'demo'
              ">
        <v-card class="gap-plus-card px-5 pb-5 mt-10 mx-auto">
          <div class="d-flex justify-space-between">
            <div class="pt-5 mr-5">
              <div class="d-flex">
                <div class="gap-plus-logo">
                  <img src="/img/gap-plus-logo.svg" />
                </div>
              </div>
              <div>(<i>Keep the value coming</i>)</div>
            </div>
            <div class="pt-1">
              <!-- to do get plan upgrade ?plan=gap_plus_individual_learner_upgrade&upgrade=true -->
              <v-btn class="mt-4 primary--text" href="/settings?tabName=plan&upgrade_to_plus=true" color="white"
                elevation="2">
                Upgrade Now
              </v-btn>
            </div>
          </div>
          <div class="mt-8">
            Regular monthly content targeting the most pressing sales issues delivered by Keenan and
            other Gap selling experts.
          </div>
        </v-card>
      </div>
      <h2 class="pt-10 mb-10">
        <div class="gap-plus-logo">
          <img src="/img/gap-plus-logo-red.svg" />
        </div>
      </h2>
      <div class="video-series-wrapper">
        <div class="series-section-wrapper" v-for="seriesSection in seriesSections" :key="seriesSection._id">
          <template v-if="seriesSection.lessons && seriesSection.lessons.length">
            <v-row justify="start" class="series-row-wrapper">
              <v-col class="px-8 mt-3" cols="12">
                <h2>{{ seriesSection.name }}</h2>
              </v-col>
            </v-row>
            <v-row justify="center" class="series-lessons-wrapper">
              <!-- <v-col class="pa-8" cols="12" md="4">
            <VideoCard
              name="Video Series - 5 Unspoken Reasons 1"
              previewImageURL="https://storage.googleapis.com/asg-assets/course-type-images/vs-1.png"
              :numLessons="1"
              estimatedTime="1h"
              :completed="false"
              :locked="false"
              :progressPercent="70"
              link="/lesson/1"
            ></VideoCard>
          </v-col> -->
              <v-col v-for="seriesLesson in seriesSection.lessons" :key="seriesLesson._id"
                @mouseover="isHovering = seriesLesson._id" @mouseout="isHovering = null" class="pa-8 unlock-btn-wrapper"
                cols="12" sm="6" md="4" lg="3">
                <div v-if="seriesLesson.locked" class="text-center unlock-btn" :class="'active'">
                  <!-- href="/sign-up?plan=5&series_sub=true" -->
                  <v-btn x-large @click="unlockVideoDialog = true" color="primary">
                    Unlock Video</v-btn>
                </div>
                <VideoCard class="mx-auto" :name="seriesLesson.name" :previewImageURL="seriesLesson.previewURL"
                  :numLessons="1" :estimatedTime="seriesLesson.videoLength" :completed="false"
                  :locked="seriesLesson.locked" :progressPercent="0"
                  :link="seriesLesson.locked ? null : `/lesson/${seriesLesson._id}`" @click="videoLink(seriesLesson)"
                  lessonType="series"></VideoCard>
                <!-- :link="
                  seriesLesson.locked
                    ? '/sign-up?plan=5&series_sub=true'
                    : `/lesson/${seriesLesson._id}`
                " -->
              </v-col>
            </v-row>
          </template>
        </div>
      </div>

      <div class="text-right pr-12 pb-2" v-if="auth.editMode">
        <v-btn href="/lesson/new" color="primary" elevation="2" fab>
          <v-icon>mdi-plus</v-icon></v-btn>
      </div>
    </v-container>
    <v-dialog v-model="unlockVideoDialog" min-width="200" max-width="590">
      <v-card>
        <h2 class="text-center pt-3">Unlock Video</h2>
        <v-card-text>
          <h4 class="mt-5">Enter Code To Unlock Video</h4>
          <div class="mt-1">
            <v-text-field class="promo-code-input" :rules="promoCode ? [(v) => !!v || 'Code is required'] : []"
              v-model="promoCode" label="Promo Code"></v-text-field>
            <div class="text-center">
              <v-btn @click="unlockLesson()" color="primary">Unlock</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
// .check{
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   margin: 60px 120px;
//   z-index: 99;
//   font-size: 95px;
//   color:rgba($color: #C7070E, $alpha: .8);
// }
.promo-code-input {
  width: 250px;
}

.course-list {
  background-color: #f2f2f2;
}

.unlock-btn-wrapper {
  position: relative;
  cursor: pointer;
}

.unlock-btn {
  &.active {
    display: block;
  }

  display: none;
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99;
}

.locked-badge-wrapper {
  &.in-active {
    display: none;
  }

  display: block;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 99;
}

.locked-badge {
  background-color: rgba(100, 0, 0, 0.3);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 15px;
}

.video-series-wrapper>div:first-of-type {
  border-top: 1px solid red;
}

.series-section-wrapper {
  border-right: 1px solid red;
  border-left: 1px solid red;
  border-bottom: 1px solid red;
  height: 100%;
}

.series-row-wrapper div {
  // border-top: none;
  // border-right: 1px solid red;
  // border-left: 1px solid red;
  // height: 100%;
}

// .series-row-wrapper:first-child {
//   border-top: 1px solid red;
// }
.series-lessons-wrapper {
  // border-right: 1px solid red;
  // border-left: 1px solid red;
  // border-bottom: 1px solid red;
}

// plus
.gap-plus-card {
  background-color: var(--v-primary-base);
  color: white;
  max-width: 500px;
}

.gap-plus-logo {
  height: 40px;
}

.gap-plus-logo img {
  height: 60px;
  margin-top: -15px;
  margin-left: -13px;
}
</style>

<script>
import { mapState } from "vuex";
import GET_SECTION from "../graphql/GetSection.gql";
import UNLOCK_LESSON from "../graphql/UnlockLesson.gql";
import router from "../router";
import analytics from "../module/analytics";
import VideoCard from "@/components/VideoCard";
import GET_ACTIVE_CHECK from "../graphql/GetActiveCheck.gql";
import GET_PROGRESS_REPORT from "../graphql/GetProgressReport.gql";


export default {
  components: { VideoCard },
  data() {
    return {
      isHovering: null,
      showHelp: false,
      sections: null,
      // showVideoCensoredDialog: false,
      activeSectionId: null,
      checkedPickUp: this.$route.query.checkpickup ? false : true,
      seriesSections: null,
      unlockVideoDialog: false,
      promoCode: null,
      gapProgress: 0,
    };
  },
  watch: {
    "auth.editMode": function (val) {
      this.getSeriesSectionList();
    },
  },
  async mounted() {
    await this.checkPaymentPlan();

    if (
      this.auth &&
      this.auth.authUser &&
      !this.auth.authUser.isTrainingUser &&
      !this.auth.authUser.isTrainingUserGapPlus &&
      this.auth.authUser.account.paymentPlan &&
      this.auth.authUser.account.paymentPlan.isTeam
    ) {
      router.push("/settings");
    }
    this.getProgressReport();
    this.getSeriesSectionList();
    analytics.page("mountCourseTypeList", [{ name: "path", value: "course-type-list" }]);
  },
  computed: mapState(["auth"]),
  methods: {
    async getProgressReport() {
      const getProgressReportResult = await this.$apollo
        .query({
          query: GET_PROGRESS_REPORT,
          fetchPolicy: "no-cache",
          variables: {
            input: {
              userId: this.auth.authUser._id,
              userReportsToId: this.auth.authUser.reportsToId,
            },
          },
        })
        .catch((error) => {
          this.getProgressReportError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });

      this.gapProgress = getProgressReportResult.data.progressReport?.[0]?.progress.sectionOrder / 8 * 100 ?? 0;
    },
    async checkPaymentPlan() {
      if (!this.auth || (this.auth && !this.auth.authUser)) {
        router.push("sign-in");
        return;
      }
      // if (
      //   ((!this.auth.authUser.isAdmin && !this.auth.authUser.account.payment) ||
      //     (this.auth.authUser.account.payment && !this.auth.authUser.account.payment.id)) &&
      //   this.auth.authUser.account.paymentPlanId != "demo" &&
      //   this.auth.authUser.account.selectedPlanId != "po_internal"
      // ) {
      //   confirm("There is an issue with your account. Please update your information.");
      //   router.push("/settings?tabName=plan&i=payment");
      //   return;
      // }
      const self = this;
      //
      // if (
      //   window.location.host.indexOf("app.gapsellingonlinetraining.com") > -1 &&
      //   this.auth.authUser.email.indexOf("myhive.io") == -1
      // ) {
      const getActiveCheckResult = await this.$apollo
        .query({
          query: GET_ACTIVE_CHECK,
          fetchPolicy: "no-cache",
        })
        .catch((error) => {
          alert("There is an issue with your account. Please update your information.");
          window.location.href = "/settings?tabName=plan";
          return;
        });

      if (!getActiveCheckResult.data.activeCheck.success) {
        alert("There is an issue with your account. Please update your information.");
        window.location.href = "/settings?tabName=plan";
      }
      // }
      //
    },
    videoLink(lesson) {
      if (lesson.locked) {
        this.unlockVideoDialog = true;
        return;
      }
      // else {
      //   window.location.href = `/lesson/${lesson._id}`;
      // }
    },
    async unlockLesson() {
      const unloclLessonResult = await this.$apollo.mutate({
        mutation: UNLOCK_LESSON,
        variables: {
          promoCode: this.promoCode,
        },
      });
      this.promoCode = null;
      this.unlockVideoDialog = false;
      this.getSeriesSectionList();
    },
    async getSeriesSectionList() {
      const getSectionResult = await this.$apollo
        .query({
          query: GET_SECTION,
          fetchPolicy: "no-cache",
          variables: {
            groupId: "default",
            type: "series",
          },
        })
        .catch((error) => {
          this.getLessonError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });

      this.seriesSections = getSectionResult.data.section;
      if (!this.auth.editMode) {
        this.seriesSections.filter((section) => {
          section.lessons = section.lessons.filter((lesson) => {
            return lesson.status != "in-active";
          });
        });
      }
    },
  },
};
</script>
